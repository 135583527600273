.casement {
    display: flex;
    gap: var(--spectrum-global-dimension-size-25);
    width: var(--spectrum-global-dimension-size-3400);
    min-width: var(--spectrum-global-dimension-size-3400);
    overflow: auto;

    &.vertical {
        flex-direction: column;
    }
}

.pane {
    background-color: var(--spectrum-alias-background-color-primary);
    border-radius: var(--spectrum-corner-radius-200);
    padding: var(--spectrum-global-dimension-size-100);

    &.raised {
        box-shadow: var(--spectrum-drop-shadow-x) var(--spectrum-drop-shadow-y) var(--spectrum-drop-shadow-blur) var(--spectrum-drop-shadow-color);
    }

    &>t-typography[variant=Heading]{
        display:flex;
        margin-block-end: var(--spectrum-global-dimension-size-100);
    }
}

.casement,
.pane {
    &.start {
        margin-inline-start: var(--spectrum-global-dimension-size-25);
    }

    &.end {
        margin-inline-end: var(--spectrum-global-dimension-size-25);
    }
}

.hidden {
    display:none;
}

sp-table[no-hover] {
    & sp-table-body {
        --spectrum-table-m-regular-row-background-color-hover: transparent;
        & sp-table-row {
            cursor:default;
            &:hover {
                --spectrum-table-row-background-color-hover: var(--spectrum-table-row-background-color);
            }
        }
    }
}

sp-field-label[side-aligned]{
    min-inline-size: var(--spectrum-global-dimension-size-1700);
}