date-picker,
datetime-picker,
time-picker {
  --input-picker-background: var(--spectrum-global-color-gray-50);
  --input-background:var(--spectrum-global-color-gray-50);
  --input-picker-color: var(--spectrum-global-color-gray-700);
  --input-color: var(--spectrum-global-color-gray-700);
  --input-border-color: var(--spectrum-alias-border-color);
  --input-border-style: solid;
  --input-border-width: var(--spectrum-alias-border-size-thin);
  --input-border-radius: var(--spectrum-alias-border-radius-regular);
  --input-focus-background: var(--spectrum-global-color-gray-50);
  /* --inner-input-focus-background: */
  --input-focus-border-color: var(--spectrum-alias-border-color-hover);
  --input-focus-color: var(--spectrum-global-color-gray-700);
  --input-focus-border-style: solid;
}