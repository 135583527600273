.module-layout {
    display: flex;
    flex-direction: column;
    height: 100%;

    .main-container {
        display: flex;
        height: 100%;
        overflow: auto;
    }

    main {
        flex: 1 1 auto;
        overflow: auto;
        z-index: 3;
    }

    .ps-sidebar-root {
        border-color: transparent;
        background-color: var(--spectrum-alias-background-color-primary);
        border-radius: var(--spectrum-corner-radius-200);
    }

    .ps-sidebar-container {
        padding: var(--spectrum-global-dimension-size-100);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: var(--spectrum-global-dimension-size-200);
    }

    .logo {
        height: var(--spectrum-global-dimension-size-300);
    }

    sp-top-nav {
        --mod-tabs-selection-indicator-color: transparent;
        --mod-tabs-item-horizontal-spacing: 0px;
        background: var(--spectrum-alias-background-color-primary);
        padding: var(--spectrum-global-dimension-size-100);
        border-bottom: solid var(--spectrum-alias-border-size-thick) var(--spectrum-alias-background-color-secondary);

        &>.module-header-slot {
            margin-inline-start: auto;
        }

        &>*:last-child{
            margin-inline-start: auto;
            margin-inline-end: 2px;
        }
    }
}