/*Flex helpers*/
[flex-fill-parent] {
    width: 100%;
    height: 100%;
    overflow:auto;
}

[flex-fill] {
    flex: 1 1 auto;
    overflow:auto;
}

[flex-parent-padded],
[flex-parent-padded-m] {
    padding: var(--spectrum-global-dimension-size-150);
}

[flex-parent-padded-s] {
    padding: var(--spectrum-global-dimension-size-75);
}

[flex-parent-padded-l] {
    padding: var(--spectrum-global-dimension-size-250);
}

.flex-layout,
[flex-layout],
[flex-layout-column],
[flex-layout-row] {
    display: flex;
}

    .flex-layout.column,
    [flex-layout][flex-column],
    [flex-layout-column] {
        flex-direction: column;
    }

[flex-gap],
[flex-gap-m]{
    gap: var(--spectrum-global-dimension-size-150);
}

[flex-gap-xs] {
    gap: var(--spectrum-global-dimension-size-25);
}

[flex-gap-s] {
    gap: var(--spectrum-global-dimension-size-75);
}

[flex-gap-l] {
    gap: var(--spectrum-global-dimension-size-250);
}

[flex-gap-xl]{
    gap: var(--spectrum-global-dimension-size-600);
}

[flex-pad] {
    padding: var(--spectrum-global-dimension-size-150);
}



.flex {
    display: flex;
    --flex-grid: 12
}

    .flex.inline {
        display: inline-flex
    }

    .flex.fullsize {
        width: 100vw;
        height: 100vh
    }

    .flex.column {
        flex-direction: column
    }

        .flex.column.reverse {
            flex-direction: column-reverse
        }

    .flex.reverse {
        flex-direction: row-reverse
    }

    .flex.wrap {
        flex-wrap: wrap
    }

    .flex.wrap-reverse {
        flex-wrap: wrap-reverse
    }

    .flex.auto > * {
        flex: 1 1 0%
    }

    .flex.center > * {
        margin: auto
    }

    .flex.justify-flex-start {
        justify-content: flex-start
    }

    .flex.justify-flex-end {
        justify-content: flex-end
    }

    .flex.justify-center {
        justify-content: center
    }

    .flex.justify-space-around {
        justify-content: space-around
    }

    .flex.justify-space-between {
        justify-content: space-between
    }

    .flex.align-flex-start {
        align-items: flex-start
    }

    .flex.align-flex-end {
        align-items: flex-end
    }

    .flex.align-center {
        align-items: center
    }

    .flex.align-stretch {
        align-items: stretch
    }

    .flex.align-baseline {
        align-items: baseline
    }

    .flex.content-flex-start {
        align-content: flex-start
    }

    .flex.content-flex-end {
        align-content: flex-end
    }

    .flex.content-center {
        align-content: center
    }

    .flex.content-space-around {
        align-content: space-around
    }

    .flex.content-space-between {
        align-content: space-between
    }

    .flex.content-stretch {
        align-content: stretch
    }

    .flex.gaps.column:not(.reverse) > :not(:last-child) {
        margin-bottom: var(--flex-gap, 1em)
    }

    .flex.gaps.column.reverse > :not(:last-child) {
        margin-top: var(--flex-gap, 1em)
    }

    .flex.gaps:not(.column):not(.reverse) > :not(:last-child) {
        margin-right: var(--flex-gap, 1em)
    }

    .flex.gaps:not(.column).reverse > :not(:last-child) {
        margin-left: var(--flex-gap, 1em)
    }

    .flex > .box.first {
        order: -1
    }

    .flex > .box.last {
        order: 1
    }

    .flex > .box.grow {
        flex: 1 0
    }

    .flex > .box.grow-fixed {
        flex: 1 0 0
    }

@media not all and (min-resolution: 0.001dpcm) {
    .flex > .box.grow-fixed {
        flex-basis: 0%
    }
}

.flex > .box.nogrow {
    flex-grow: 0;
    flex-basis: auto
}

.flex > .box.noshrink {
    flex-shrink: 0
}

.flex > .box.center {
    margin: auto
}

.flex > .box.left {
    margin-left: 0;
    margin-right: auto
}

.flex > .box.right {
    margin-right: 0;
    margin-left: auto
}

.flex > .box.top {
    margin-top: 0;
    margin-bottom: auto
}

.flex > .box.bottom {
    margin-bottom: 0;
    margin-top: auto
}

.flex > .box.self-flex-start {
    align-self: flex-start
}

.flex > .box.self-flex-end {
    align-self: flex-end
}

.flex > .box.self-center {
    align-self: center
}

.flex > .box.self-stretch {
    align-self: stretch
}

.flex > .box.self-baseline {
    align-self: baseline
}

.flex.grid-1 > * {
    --flex-grid: 1
}

.flex .col-1 {
    --flex-col: 1
}

.flex.grid-2 > * {
    --flex-grid: 2
}

.flex .col-2 {
    --flex-col: 2
}

.flex.grid-3 > * {
    --flex-grid: 3
}

.flex .col-3 {
    --flex-col: 3
}

.flex.grid-4 > * {
    --flex-grid: 4
}

.flex .col-4 {
    --flex-col: 4
}

.flex.grid-5 > * {
    --flex-grid: 5
}

.flex .col-5 {
    --flex-col: 5
}

.flex.grid-6 > * {
    --flex-grid: 6
}

.flex .col-6 {
    --flex-col: 6
}

.flex.grid-7 > * {
    --flex-grid: 7
}

.flex .col-7 {
    --flex-col: 7
}

.flex.grid-8 > * {
    --flex-grid: 8
}

.flex .col-8 {
    --flex-col: 8
}

.flex.grid-9 > * {
    --flex-grid: 9
}

.flex .col-9 {
    --flex-col: 9
}

.flex.grid-10 > * {
    --flex-grid: 10
}

.flex .col-10 {
    --flex-col: 10
}

.flex.grid-11 > * {
    --flex-grid: 11
}

.flex .col-11 {
    --flex-col: 11
}

.flex.grid-12 > * {
    --flex-grid: 12
}

.flex .col-12 {
    --flex-col: 12
}

.flex.grid-1 > *, .flex.grid-2 > *, .flex.grid-3 > *, .flex.grid-4 > *, .flex.grid-5 > *, .flex.grid-6 > *, .flex.grid-7 > *, .flex.grid-8 > *, .flex.grid-9 > *, .flex.grid-10 > *, .flex.grid-11 > *, .flex.grid-12 > * {
    flex: 0 4 calc(100% / var(--flex-grid))
}

.flex > .col-1, .flex > .col-2, .flex > .col-3, .flex > .col-4, .flex > .col-5, .flex > .col-6, .flex > .col-7, .flex > .col-8, .flex > .col-9, .flex > .col-10, .flex > .col-11, .flex > .col-12 {
    flex: 0 1 calc(100% / var(--flex-grid) * var(--flex-col))
}

.flex > input, .flex > textarea {
    min-width: 0
}
