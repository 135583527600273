html, body {
  padding:0px;
  margin:0px;
}

[padded-xs]{
  padding: var(--spectrum-global-dimension-size-50);
}

[padded-s] {
  padding: var(--spectrum-global-dimension-size-100);
}

[padded],
[padded-m] {
    padding: var(--spectrum-global-dimension-size-150);
}

[padded-l] {
  padding: var(--spectrum-global-dimension-size-200);
}

[padded-xl] {
  padding: var(--spectrum-global-dimension-size-250);
}